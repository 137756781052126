<template>
  <div class="login_pannal">
    <div class="wrap">
      <h2 style="text-align: left">欢迎登录聚客量化</h2>
      <div class="line"></div>
      <el-form ref="form" class="register-email" :model="form" :rules="rules">
        <el-form-item prop="account">
          <el-input v-model="form.account" placeholder="账号"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input v-model="form.password" placeholder="密码" type="password">
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="submit_button" @click="submit">
            登录
          </el-button>
        </el-form-item>
        <el-form-item class="operate">
          <el-checkbox
            v-model="form.checked"
            label="记住账号"
            size="large"
          ></el-checkbox>
          <router-link style="text-decoration: none" to="/password">
            <span class="forget_password link">忘记密码</span>
          </router-link>
        </el-form-item>
        <a href="http://jukequant.com/register"><p class="link">没有账号? 去注册</p></a>
      </el-form>
    </div>
  </div>
</template>

<script>
import { validPassword, validMobile, validEmail } from '@/utils/validate'
import { login } from '@/api/auth'
import { setToken, getToken } from '@/utils/auth'

export default {
  data() {
    return {
      form: {
        account: '',
        password: '',
        checked: false,
      },
      rules: {
        account: [
          { required: true, trigger: 'blur', validator: this.validAccount }
        ],
        password: [
          { required: true, trigger: 'blur', validator: this.validPassword }
        ]
      }
    }
  },

  methods: {
    validAccount(rule, value, callback) {
      if (validMobile(value) || validEmail(value)) {
        callback()
      } else {
        callback(new Error('账号错误'))
      }
    },

    validPassword(rule, value, callback) {
      if (validPassword(value)) {
        callback()
      } else {
        callback(new Error('密码错误'))
      }
    },

    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const data = {
            username: this.form.account,
            password: this.form.password,
            product_id: 'official_website'
          }
          login(data).then((result) => {
            if (result) {
              this.$store.commit('saveToken', result.data.token)
              this.$store.commit('saveUserInfo', result.data)
              setToken(result.data.token)
              sessionStorage.setItem('token', result.data.token)
              sessionStorage.setItem('userInfo', JSON.stringify(result.data))
              this.$router.push('/')
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.login_pannal {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;

  .wrap {
    width: 400px;
    border: solid 1px rgba(0, 0, 0, 0.06);
    padding: 20px 40px;
    border-radius: 10px;

    .line {
      height: 1px;
      background-color: rgba(0, 0, 0, 0.1);
      margin-bottom: 20px;
    }

    .submit_button {
      width: 100%;
    }

    .operate :deep(.el-form-item__content) {
      display: flex;
      justify-content: space-between;

      width: 100%;
    }
  }
}

.link {
  color: #1890ff;
  cursor: pointer;
}
</style>
