<template>
  <div class="login" :class="{ isMobile }">
    <el-row>
      <el-col :span="12" :xs="0">
        <img src="../../assets/login-bgc.png" alt="" />
      </el-col>
      <el-col :span="12" :xs="24">
        <login-pannal></login-pannal>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import LoginPannal from './components/loginPannal.vue'

export default {
  components: {
    LoginPannal
  },

  mounted() {
    if (this.$store.state.token) {
      this.$router.replace('/')
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  height: 100vh;

  img {
    width: 100%;
    height: 100%;
  }
}
</style>
